
/* css grid layout */

.grid-8-col {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

@media (min-width: 700px) {
  .grid-8-col {
    grid-template-columns: repeat(2, calc(50% - 8px));
  }
}

@media (min-width: 1000px) {
  .grid-8-col {
    grid-template-columns: repeat(4, calc(25% - 12px));
  }
}

@media (min-width: 1200px) {
  .grid-6-col {
    grid-template-columns: repeat(8, calc(25% - 12px));
  }
}

.grid-6-col {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

@media (min-width: 700px) {
  .grid-6-col {
    grid-template-columns: repeat(2, calc(50% - 8px));
  }
}

@media (min-width: 1000px) {
  .grid-6-col {
    grid-template-columns: repeat(4, calc(25% - 12px));
  }
}

@media (min-width: 1200px) {
  .grid-6-col {
    grid-template-columns: repeat(6, calc(25% - 12px));
  }
}

.grid-4-col {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

@media (min-width: 700px) {
  .grid-4-col {
    grid-template-columns: repeat(2, calc(50% - 8px));
  }
}

@media (min-width: 1000px) {
  .grid-4-col {
    grid-template-columns: repeat(4, calc(25% - 12px));
  }
}

.grid-3-col {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

@media (min-width: 700px) {
  .grid-3-col {
    grid-template-columns: repeat(2, calc(50% - 8px));
  }
}

@media (min-width: 1000px) {
  .grid-3-col {
    grid-template-columns: repeat(3, calc(33% - 12px));
  }
}

.grid-2-col {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

@media (min-width: 700px) {
  .grid-2-col {
    grid-template-columns: repeat(2, calc(50% - 8px));
  }
}

.grid-1-col {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}
