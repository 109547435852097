@import "sizes";

/* layout overrides */
app-authenticated > router-outlet + app-patient > app-loading-bar > mat-progress-bar,
app-authenticated > router-outlet + app-patient > app-patient-top-nav > mat-toolbar,
app-authenticated > router-outlet.has-tabs + app-calendar > mat-toolbar,
app-authenticated > router-outlet.has-tabs + app-theater-slate > mat-toolbar {
  margin-top: 104px !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

app-authenticated > router-outlet + app-calendar > mat-toolbar,
app-authenticated > router-outlet + app-theater-slate > mat-toolbar {
  margin-top: 56px !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: $grid-breakpoint-sm) {
  app-authenticated > router-outlet + app-patient > app-loading-bar > mat-progress-bar,
  app-authenticated > router-outlet + app-patient > app-patient-top-nav > mat-toolbar,
  app-authenticated > router-outlet.has-tabs + app-calendar > mat-toolbar,
  app-authenticated > router-outlet.has-tabs + app-theater-slate > mat-toolbar{
    margin-top: 112px  !important;
    overflow-y: visible;
  }

  app-authenticated > router-outlet + app-calendar > mat-toolbar,
  app-authenticated > router-outlet + app-theater-slate > mat-toolbar {
    margin-top: 64px !important;
    overflow-y: visible;
  }
}

app-authenticated > router-outlet + app-slate-selector .mat-container,
app-authenticated > router-outlet + app-dashboard .mat-container,
app-authenticated > router-outlet + app-builder .mat-container,
app-authenticated > router-outlet + app-builder .editor {
  margin-top: 76px  !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
app-authenticated > router-outlet + app-admin > mat-toolbar,
app-authenticated > router-outlet + app-settings > mat-toolbar,
app-authenticated > router-outlet + app-new-patient .mat-container,
app-authenticated > router-outlet + app-builder .editor {
  margin-top: 64px !important;
}
app-authenticated > router-outlet.has-tabs + app-slate-selector .mat-container,
app-authenticated > router-outlet.has-tabs + app-dashboard .mat-container,
app-authenticated > router-outlet.has-tabs + app-builder .mat-container {
  margin-top: 120px !important;
}
app-authenticated > router-outlet.has-tabs + app-admin > mat-toolbar,
app-authenticated > router-outlet.has-tabs + app-settings > mat-toolbar,
app-authenticated > router-outlet.has-tabs + app-new-patient .mat-container,
app-authenticated > router-outlet.has-tabs + app-builder .editor {
  margin-top: 112px !important;
}
