// padding extensions
.pt-7, .py-7 {
    padding-top: 7rem !important;
}
.pb-7, .py-7 {
    padding-bottom: 7rem !important;
}
.pl-7, .px-7 {
    padding-left: 7rem !important;
}
.pr-7, .px-7 {
    padding-right: 7rem !important;
}

.pt-6, .py-6 {
    padding-top: 5rem !important;
}
.pb-6, .py-6 {
    padding-bottom: 5rem !important;
}
.pl-6, .px-6 {
    padding-left: 5rem !important;
}
.pr-6, .px-6 {
    padding-right: 5rem !important;
}

// margin extensions
.mt-9, .my-9 {
  padding-top: 9rem !important;
}
.mb-9, .my-9 {
  padding-bottom: 9rem !important;
}

.mt-7, .my-7 {
    padding-top: 7rem !important;
}
.mb-7, .my-7 {
    padding-bottom: 7rem !important;
}
.ml-7, .mx-7 {
    padding-left: 7rem !important;
}
.mr-7, .mx-7 {
    padding-right: 7rem !important;
}

.mt-6, .my-6 {
    padding-top: 5rem !important;
}
.mb-6, .my-6 {
    padding-bottom: 5rem !important;
}
.ml-6, .mx-6 {
    padding-left: 5rem !important;
}
.mr-6, .mx-6 {
    padding-right: 5rem !important;
}
