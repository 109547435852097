@use '@angular/material' as mat;
@use 'sass:map';
@import "functions";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

// Define a custom mixin that takes in the current theme and outputs css vars
@mixin theme-color-grabber($theme) {
  // Parse the theme and create variables for each color in the palette
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, 'primary');
  $accent: map.get($config, 'accent');
  $warn: map.get($config, 'warn');
  $foreground: map.get($config, 'foreground');
  $background: map.get($config, 'background');
  $is-dark: map.get($config, 'is-dark');
  --mat-color-primary: #{mat.m2-get-color-from-palette($primary)};
  --mat-color-accent: #{mat.m2-get-color-from-palette($accent)};
  --mat-color-warn: #{mat.m2-get-color-from-palette($warn)};
  --mat-color-primary-rgb: #{toRGBVar(mat.m2-get-color-from-palette($primary))};
  --mat-color-accent-rgb: #{toRGBVar(mat.m2-get-color-from-palette($accent))};
  --mat-color-warn-rgb: #{toRGBVar(mat.m2-get-color-from-palette($warn))};
  --mat-page-background: #{mat.m2-get-color-from-palette($background, 'background')};
  --mat-background: #{mat.m2-get-color-from-palette($background, 'card')};;
  --mat-text: #{mat.m2-get-color-from-palette($foreground, 'text')};
  --mat-text-invert: white;
  --mat-is-dark: #{$is-dark};
}

$default-typography: mat.m2-define-typography-config();
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$xmedical-theme: mat.m2-define-light-theme((
  color: (
    primary: mat.m2-define-palette(mat.$m2-indigo-palette),
    accent: mat.m2-define-palette(mat.$m2-indigo-palette, A200, A100, A400),
  ),
  typography: $default-typography
));

$xmedical-light-theme: mat.m2-define-light-theme((
  color: (
    primary: mat.m2-define-palette(mat.$m2-blue-palette),
    accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400),
  ),
  typography: $default-typography
));

$xmedical-blue-theme: mat.m2-define-dark-theme((
  color: (
    primary: mat.m2-define-palette(mat.$m2-indigo-palette),
    accent: mat.m2-define-palette(mat.$m2-indigo-palette, A200, A100, A400),
  ),
  typography: $default-typography
));

$xmedical-dark-orange-theme: mat.m2-define-dark-theme((
  color: (
    primary: mat.m2-define-palette(mat.$m2-deep-orange-palette),
    accent: mat.m2-define-palette(mat.$m2-deep-orange-palette, A200, A100, A400),
  ),
  typography: $default-typography
));

$xmedical-orange-theme: mat.m2-define-light-theme((
  color: (
    primary: mat.m2-define-palette(mat.$m2-deep-orange-palette),
    accent: mat.m2-define-palette(mat.$m2-deep-orange-palette, A200, A100, A400),
  ),
  typography: $default-typography
));

// base theme - orange
@include mat.typography-hierarchy($default-typography);
@include mat.all-component-themes($xmedical-orange-theme);
:root {
  @include theme-color-grabber($xmedical-orange-theme);
}

// light theme - light blue
html[data-theme="blue"],
.theme-blue {
  @include mat.all-component-colors($xmedical-theme);
  @include theme-color-grabber($xmedical-theme);
}

// light theme - light blue
html[data-theme="lightblue"],
.theme-blue-light {
  @include mat.all-component-colors($xmedical-light-theme);
  @include theme-color-grabber($xmedical-light-theme);
}


// dark theme - blue-dark
html[data-theme="darkblue"],
.theme-blue-dark {
  @include mat.all-component-colors($xmedical-blue-theme);
  @include theme-color-grabber($xmedical-blue-theme);
}


// dark theme - orange-dark
html[data-theme="darkorange"],
.theme-orange-dark {
  @include mat.all-component-colors($xmedical-dark-orange-theme);
  @include theme-color-grabber($xmedical-dark-orange-theme);
}
