$roboto-font-path: "/assets/fonts" !default;
@import "roboto-fontface/css/roboto/sass/roboto-fontface";
$material-design-icons-font-directory-path: '../dist/fonts/';
@import 'material-design-icons-iconfont/src/material-design-icons';
@import "reboot";
@import "app-theme";
@import "sizes";
@import "extensions";
@import "flexbox";
@import "grid";
@import "layout";
@import "utilities";
@import "module-tab-offsets";
@import "fonts";
@import "flatpickr";

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
body.lock-scroll {
  overflow: hidden;
}
b,
strong {
    font-weight: bolder;
}


.rotate {
    animation-name: spin;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.x-invert .rotate,
.y-invert .rotate {
    animation-direction: reverse;
}

/* prevent selection on menu items */
/* https://stackoverflow.com/a/4407335/3152906 */
.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.x-invert {
    transform: scaleX(-1);
}
.y-invert {
    transform: scaleY(-1);
}

.x-invert.y-invert {
    transform: scale(-1);
}

.search-value-highlight{
    display: inline-block;
    background-color: rgba(var(--mat-color-primary-rgb), 0.5);
    font-weight: inherit;
}

.multiline {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

mat-hint.standalone {
  font-size: 75%;
  margin: 0 1em;
  display: inline-block;
}

.mat-opacity-7 {
  opacity: 0.7 !important;
}

/* material search form overrides */

.material-search-override .mat-form-field-wrapper {
  padding: 0.25em 0 0.25em 0;
}

.material-search-override .mat-form-field-appearance-fill .mat-mdc-form-field-flex,
.material-search-override .mat-form-field-appearance-fill .mat-form-field-flex {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
}

.material-search-override .mat-form-field-appearance-fill .mat-mdc-form-field-infix,
.material-search-override .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0.5em 0.25em 0 !important;
  border-top: 0;
  min-height: 0;
}

.material-search-override .mat-form-field-underline {
  bottom: 0;
}

.material-search-override .mat-form-field-appearance-fill .mat-mdc-form-field-focus-overlay {
  opacity: 0;
  display: none;
}

.material-search-override .mat-form-field-appearance-fill .mat-form-field-label-wrapper {
  top: 0;
  padding-top: 0;
}

.material-search-override .mat-form-field-appearance-fill .mat-mdc-form-field-infix input.mat-mdc-input-element,
.material-search-override .mat-form-field-appearance-fill .mat-form-field-infix input.mat-input-element {
  margin-top: 0;
}

/* material menu dropdown overrides */

.mat-mdc-menu-panel.mat-mdc-menu-panel.mat-menu-full-width,
.mat-mdc-menu-panel.mat-menu-full-width {
  max-width: unset;
}


.mat-mdc-menu-panel {
  min-height: 32px !important;
}

.mat-mdc-menu-panel.quick-note .mat-mdc-menu-content {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: -4px;
}

.mat-autocomplete-panel {
  max-height: calc(100vh - 64px) !important;
}

.mat-mdc-form-field-infix {
  min-width: 120px;
  width: auto;
}

.mat-mdc-form-field.no-label .mat-mdc-form-field-flex,
.mat-form-field.no-label .mat-form-field-infix {
  padding-top: 0;
  border-top: none;
}

.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-invalid .mat-mdc-text-field-wrapper {
  background-color: rgba(var(--mat-color-warn-rgb), 0.1);
}

.mat-mdc-form-field.mat-background-reset .mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color: inherit !important;
}

  /* material toolbar spacer  */

.flex-spacer,
.toolbar-spacer {
  flex: 1 1 auto;
}

/* utilities */

.hidden {
  display: none !important;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-25 {
  width: 25% !important;
}

.w-33 {
  width: 33% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

/* material dialog responsive */
.mat-dialog-lg,
.mat-dialog-responsive {
  width: calc(100vw - 32px);
  max-width: 100% !important;
}

@media (min-width: 700px){
  .mat-dialog-responsive {
    width: 70vw;
  }
}

@media (min-width: 1000px){
  .mat-dialog-responsive {
    width: 50vw;
  }
}

@media (min-width: 700px){
  .mat-dialog-lg {
    width: 90vw;
  }
}

@media (min-width: 1000px){
  .mat-dialog-lg {
    width: 70vw;
  }
}

/* material tag */

.mat-tag {
  border-radius: 12px !important;
  min-height: 24px !important;
}

/* mat list custom */

.mat-list-dynamic.mat-mdc-list-base .mat-mdc-list-item,
.mat-list-dynamic.mat-list-base .mat-list-option {
  height: auto !important;
}

/* material card custom */

.mat-mdc-card .mat-mdc-card-title button  {
  right: -12px;
}

.mat-card.compact,
.mat-mdc-card.compact .mat-mdc-card-content {
  padding: 12px;
}

.mat-mdc-card.compact:not(:first-of-type) {
  padding-top: 0;
}

.mat-mdc-card.compact .mat-mdc-card-title button {
  right: -8px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  padding: 4px;
}
.mat-mdc-card.compact .mat-mdc-card-title button .material-icons {
  font-size: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-title-reduced {
  font-size: 20px;
}
.mat-mdc-card.compact .mat-mdc-card-title,
.mat-mdc-card.compact .mat-mdc-card-subtitle,
.mat-mdc-card.compact .mat-mdc-card-content, {
  margin-bottom: 8px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-list {
  border-radius: 4px;
  margin-bottom: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
}

html[data-theme="dark"] .mat-card-list,
.theme-dark .mat-card-list {
  border-color: rgba(255, 255, 255, 0.12);
}

.mat-card-list .mat-mdc-card,
.mat-card-list .mat-card {
  box-shadow: none !important;
}

.mat-card-list .mat-mdc-card:not(:first-of-type),
.mat-card-list .mat-card:not(:first-of-type) {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.mat-card-list .mat-mdc-card:not(:last-of-type),
.mat-card-list .mat-card:not(:last-of-type) {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.mat-card-list .mat-mdc-card:last-of-type mat-divider:last-of-type,
.mat-card-list .mat-card:last-of-type mat-divider:last-of-type {
  display: none;
}

.mat-card-list .mat-mdc-card mat-divider {
  margin-left: 16px;
  margin-right: 16px;
}
.mat-card-list .mat-mdc-card.compact mat-divider {
  margin-left: 8px;
  margin-right: 8px;
}

.mat-card-list .mat-mdc-card.compact mat-divider.mat-divider.mat-divider-inset  {
  margin-left: 40px;
}

.mat-mdc-card.compact .mat-mdc-card-title,
.mat-card.compact .mat-card-title {
  font-size: 16px;
  margin-bottom: 0 !important;
}

.mat-mdc-card.compact:not(:first-of-type) .mat-mdc-card-title-group {
  padding-top: 8px;
}

.mat-mdc-card .mat-mdc-card-title.primary {
  color: var(--mat-color-primary);
}

.mat-mdc-card .mat-mdc-card-title.primary button {
  color: initial;
}

/* mat-button-group styles */
.mat-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;

}
.mat-button-group > .mdc-button,
.mat-button-group > .mat-mdc-unelevated-button,
.mat-button-group > .mat-mdc-raised-button,
.mat-button-group > .mat-button {
  flex-grow: 1;
  min-width: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}

.mat-button-group .mdc-button:not(:first-of-type),
.mat-button-group .mat-mdc-unelevated-button:not(:first-of-type),
.mat-button-group .mat-mdc-raised-button:not(:first-of-type),
.mat-button-group .mat-button:not(:first-of-type) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.mat-button-group .mdc-button:not(:last-of-type),
.mat-button-group .mat-mdc-unelevated-button:not(:last-of-type),
.mat-button-group .mat-mdc-raised-button:not(:last-of-type),
.mat-button-group .mat-button:not(:last-of-type) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

/* material button sizes */
.mat-button, .mdc-button,
.mat-unelevated-button, .mdc-button.mdc-button--unelevated,
.mat-raised-button, .mdc-button.mdc-button--raised,
.mat-outlined-button, .mdc-button.mdc-button--outlined {
  min-width: 128px;
}


/* custom form control for draft comments */

.draft-custom-form-control {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.42);;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out;
  box-shadow: none;
  outline: none;
}

.draft-custom-form-control:hover,
.draft-custom-form-control:focus,
.draft-custom-form-control:active {
  border: 1px solid rgba(0, 0, 0, 0.87);;
}

/* cursor styles */
.cursor-pointer {
  cursor: pointer;
}

/* mat table responsive queries */

td.mat-mdc-cell, th.mat-mdc-header-cell {
  display: table-cell;
}

td.mat-mdc-cell.d-responsive.d-xs, th.mat-mdc-header-cell.d-responsive.d-xs,
td.mat-mdc-cell.d-responsive.d-md, th.mat-mdc-header-cell.d-responsive.d-md,
td.mat-mdc-cell.d-responsive.d-lg, th.mat-mdc-header-cell.d-responsive.d-lg,
td.mat-mdc-cell.d-responsive.d-xl, th.mat-mdc-header-cell.d-responsive.d-xl,
td.mat-mdc-cell.d-responsive.d-xxl, th.mat-mdc-header-cell.d-responsive.d-xxl {
  display: none;
}

@media (min-width: $grid-breakpoint-xs) {
  td.mat-mdc-cell.d-responsive.d-xs, th.mat-mdc-header-cell.d-responsive.d-xs, {
    display: table-cell;
  }
}
@media (min-width: $grid-breakpoint-md) {
  td.mat-mdc-cell.d-responsive.d-xs, th.mat-mdc-header-cell.d-responsive.d-xs,
  td.mat-mdc-cell.d-responsive.d-md, th.mat-mdc-header-cell.d-responsive.d-md{
    display: table-cell;
  }
}
@media (min-width: $grid-breakpoint-lg) {
  td.mat-mdc-cell.d-responsive.d-xs, th.mat-mdc-header-cell.d-responsive.d-xs,
  td.mat-mdc-cell.d-responsive.d-md, th.mat-mdc-header-cell.d-responsive.d-md,
  td.mat-mdc-cell.d-responsive.d-lg, th.mat-mdc-header-cell.d-responsive.d-lg{
    display: table-cell;
  }
}
@media (min-width: $grid-breakpoint-xl) {
  td.mat-mdc-cell.d-responsive.d-xs, th.mat-mdc-header-cell.d-responsive.d-xs,
  td.mat-mdc-cell.d-responsive.d-md, th.mat-mdc-header-cell.d-responsive.d-md,
  td.mat-mdc-cell.d-responsive.d-lg, th.mat-mdc-header-cell.d-responsive.d-lg,
  td.mat-mdc-cell.d-responsive.d-xl, th.mat-mdc-header-cell.d-responsive.d-xl {
    display: table-cell;
  }
}
@media (min-width: $grid-breakpoint-xxl) {
  td.mat-mdc-cell.d-responsive.d-xs, th.mat-mdc-header-cell.d-responsive.d-xs,
  td.mat-mdc-cell.d-responsive.d-md, th.mat-mdc-header-cell.d-responsive.d-md,
  td.mat-mdc-cell.d-responsive.d-lg, th.mat-mdc-header-cell.d-responsive.d-lg,
  td.mat-mdc-cell.d-responsive.d-xl, th.mat-mdc-header-cell.d-responsive.d-xl,
  td.mat-mdc-cell.d-responsive.d-xxl, th.mat-mdc-header-cell.d-responsive.d-xxl {
    display: table-cell;
  }
}

/* material menu reset */

 .mat-mdc-menu-content {
   padding: 0 !important;
 }

/*  material list */
.mat-mdc-list-item.mat-list-item-compact {
    height: 36px !important;
}
.mat-mdc-list-item.clickable:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

/* quill specific overrides */

.ql-tooltip {
  left: unset !important;
}
