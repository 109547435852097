$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 2000px
);

$grid-breakpoint-xs:   map-get($grid-breakpoints, "xs");
$grid-breakpoint-sm:   map-get($grid-breakpoints, "md");
$grid-breakpoint-md:   map-get($grid-breakpoints, "md");
$grid-breakpoint-lg:   map-get($grid-breakpoints, "lg");
$grid-breakpoint-xl:   map-get($grid-breakpoints, "xl");
$grid-breakpoint-xxl:   map-get($grid-breakpoints, "xxl");
$grid-breakpoint-xxxl:   map-get($grid-breakpoints, "xxxl");

$sidenav-width: 13rem;
$draft-width: 8rem;

.mat-container{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: $grid-breakpoint-xs) {
  .mat-container {
    max-width: 540px;
  }
}
@media (min-width: $grid-breakpoint-md) {
  .mat-container {
    max-width: 720px;
  }
}
@media (min-width: $grid-breakpoint-lg) {
  .mat-container {
    max-width: 960px;
  }
}
@media (min-width: $grid-breakpoint-xl) {
  .mat-container {
    max-width: 1140px;
  }
}
@media (min-width: $grid-breakpoint-xxl) {
  .mat-container {
    max-width: 1320px;
  }
}

/* Rules for sizing the icon. */
mat-icon.md-16 {
  font-size: 16px !important;
  width: 16px;
  height: 16px;
}
mat-icon.md-18 {
  font-size: 18px !important;
  width: 18px;
  height: 18px;
}
mat-icon.md-20 {
  font-size: 20px !important;
  width: 20px;
  height: 20px;
}
mat-icon.md-24 {
  font-size: 24px !important;
  width: 24px;
  height: 24px;
}
mat-icon.md-28 {
  font-size: 28px !important;
  width: 28px;
  height: 28px;
}
mat-icon.md-32 {
  font-size: 32px !important;
  width: 32px;
  height: 32px;
}
mat-icon.md-36 {
  font-size: 36px !important;
  width: 36px;
  height: 36px;
}
mat-icon.md-48 {
  font-size: 48px !important;
  width: 48px;
  height: 48px;
}
