/* Font settings */

.mat-bold-300 {
  font-weight: 300 !important;
}

.mat-bold-400 {
  font-weight: 400 !important;
}

.mat-bold-500 {
  font-weight: 500 !important;
}

.mat-bolder {
  font-weight: bolder !important;
}

.mat-bold {
  font-weight: bold !important;
}

.mat-text-warn {
  color: var(--mat-color-warn) !important;
}

.mat-bg-warn {
  background-color: var(--mat-color-warn) !important;
}

.text-light {
  color: var(--mat-text-invert) !important;
}
